.appointments {

    .nav {

        padding: 20px 0;

        &.nav-tabs {

            border: none;
        }

        .nav-item {
            margin: 0 15px;
            .nav-link {
                border: none;
                font-size: 16px;
                font-weight: 500;
                color: #5e5e5e;
                cursor: pointer;
                border-radius: 0;

                &.active {
                    border-bottom: 1px solid $theme-color;
                    color: $theme-color;
                }
            }
        }
    } 

    .tab-content {

        margin-top: 50px;

        .date-error {
            text-align: center;

            img {
                width: 50%;
            }
            h1 {
                font-size: 20px;
                margin-top: 25px;
                color: $theme-color;
            }
            a {
                margin-top: 25px  !important;
                display: inline-block;
                background-color: #a60808;
                color: #fff;
                border-color: #a60808;
                border-radius: 0;
            }
        }

        .tab-pane {

            .card {

                border: none;
                background-color: #FFF;
                box-shadow: 0 5px 40px 5px rgba(112, 119, 125, 0.25);
                margin-bottom: 30px;
                

                .card-header {
                    position: relative;
                    background-color: #FFF;
                    border: none;
                    padding: 20px;

                    .user-info {

                        display: flex;

                        @media (max-width : 500px) {
                            display: block;
                        }

                        .user-img {

                            margin-right: 25px;

                            img {
                                width: 100px;
                                height: 100px;
                                border-radius: 5px;
                            }
                        }
                        .user {

                            .info {

                                margin-bottom: 10px;

                                @media (max-width : 500px) {
                                    margin-top: 10px;
                                }

                                h5 {
                                    margin: 0;
                                    color: #000;
                                }

                                .spec {
                                    color: $theme-color;
                                }
                            }

                            .date-box {
                                display: flex;
                                align-items: center;
                                justify-content: space-between;

                                .date {
                                    margin-right: 15px;

                                    @media (max-width : 500px) {
                                        margin-right: 0px;
                                    }
                                }
                            }
                        }
                    }

                    .app-btns {
                        margin-top: 25px;
                        display: flex;
                        justify-content: space-between;
                        button {

                            border: none;
                            background: none;
                            background-color: $theme-color;
                            color: #FFF;
                            padding: 10px 20px;
                            text-align: center;
                            font-size: 16px;
                            background: linear-gradient(145deg, #000 0%, rgba(0,0,0,0.85098) 100%);
                            text-transform: capitalize;

                            &.cancel {

                                background: #dc3545;
                            }

                            &.finish {
                                display: block;
                                width: 100%;
                            }
                        }
                    }

                    .status {
                        position: absolute;
                        top: 12px;
                        right: 20px;

                        &.pending {
                            color: $theme-color;
                        }

                        &.confirmed {
                            color: #3da744;
                        }

                        &.canceled {
                            color: #dc3545;
                        }

                        &.finished {
                            color: #aaa;
                        }
                    }
                }
            }
        }
    }

    
}
.modal {

    .modal-dialog {

        &.custom {
            .modal-content {

                background-color: #FFF  !important;
            }

            .modal-body {

                .card {
                    border: none;
                    margin-bottom: 25px;
                    box-shadow: 0 0 37.8px 0 #f2f2f2;
                    padding: 20px;
                    display: block;

                    .title {
                        font-size: 14px;
                        color: $theme-color;
                    }

                    .text {

                        font-size: 18px;
                    }

                    .service-needed {
                        font-size: 12px;
                        font-weight: 600;
                        background-color: $theme-color;
                        color: #FFF;
                        padding: 10px;
                        margin-left: 5px;
                        display: inline-block;
                        margin-bottom: 5px;
                        border-radius: 4px;
                    }
                }
                
            }
        }

    }

}