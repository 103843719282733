.footer {

    .footer-top {

        background-color: #000;
        padding: 80px 0;

        .footer-content {

            @media(max-width: 500px) {

                text-align: center;
            }

            p {
                color: #c6c9d8;
                font-size: 14px;
                opacity: 0.75;
                margin-top: 30px;
                padding-right: 50px;

                @media(max-width: 500px) {

                    padding-right: 0;
                    margin-bottom: 20px;
                }
            }

            .footer-title {

                 font-size: 25px;
                 font-weight: 400;
                 color: #fff;
                 margin-bottom: 30px;
            }

            .footer-links {

                list-style: none;
                padding: 0;
                margin: 0;

                li {

                    margin-bottom: 10px;

                    a {

                        display: flex;
                        align-items: center;
                        color: #c6c9d8;
                        opacity: 0.75;
                        font-size: 14px;

                        @media(max-width: 500px) {

                            justify-content: center;
                        }

                        i {

                            display: inline-block;
                            margin-right: 10px;
                            font-size: 14px;
                        }
                    }
                }
            }
        }
    }

    .copy-right {

        background-color: #141414;
        color: #c6c9d8;
        padding: 20px 0;

        .footer-social {

            display: flex;
            align-items: center;

            @media(max-width: 500px) {

                display: block;
                text-align: center;
                margin-bottom: 20px;
            }

            .social-title {

                font-size: 14px;
                font-weight: 300;
                color: #c6c9d8;
                opacity: .75;
                margin-bottom: 0;
                margin-right: 24px;

                @media(max-width: 500px) {

                    margin-bottom: 20px;
                }
           }

            .social-list {

                list-style: none;
                padding: 0;
                margin: 0;
                display: flex;

                @media(max-width: 500px) {

                    justify-content: center;
                }

                li {

                    margin-right: 7px;

                    a {

                        display: inline-block;
                        width: 30px;
                        height: 30px;
                        border-radius: 50%;
                        text-align: center;
                        line-height: 30px;
                        background-color: rgba(255, 255, 255, .1);
                        color: #c6c9d8;
                        opacity: .75;
                    }
                }
            }
        }

        .text {

            text-align: right;

            @media(max-width: 500px) {

                text-align: center;
            }

            p {
                font-size: 14px;
                font-weight: 300;
                color: #c6c9d8;
                opacity:.75;
                margin-bottom: 0;
            }
        }
    }
}