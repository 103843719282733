.side-bar {

    padding-right: 20px;
    border-right: 1px solid rgba(119,119,119,0.2);;

    .title {

        font-size: 24px;
        margin: 0;
        padding-bottom: 20px;
        border-bottom: 1px solid rgba(119,119,119,0.2);;
    }

    .profile-menu {

        margin: 0;
        padding: 0;
        list-style: none;
        margin-top: 30px;

        li {

            a {

                display: block;
                margin-bottom: 15px;
                padding: 7px 15px;
                color: #000;

                &.logout {

                    background-color: $theme-color;
                    color: #FFF;
                }

                &.loading {

                    opacity: .6;
                    cursor: not-allowed;
                    background-color: $theme-color;
                    color: #FFF;

                }

                &.active {

                    background-color: #EEE;
                }
            }
        }
    }
}