.work-box {

    background-color: rgb(255, 255, 255);
    border-radius: 24px;
    box-shadow: 0 0 8px rgba(0, 0, 0,.18);
    position: relative;

    .work-badge {

        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        background-color: #a60808;
        padding: 10px 20px;
        color: #FFF;
        border-top-left-radius: 24px;
        border-bottom-right-radius: 24px;
    }

    .work-img {

        width: 100%;
        height: 285px;
        position: relative;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-top-left-radius: 24px;
            border-top-right-radius: 24px;
        }

        .play-icon {

            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            cursor: pointer;

            img {

                width: 60px;
                height: 60px;
            }
        }
    }

    .work-title {

        text-align: center;
        font-size: 18px;
        padding: 30px 20px;
        font-weight: 400;
        border: 1px solid #cbcbcb;
        border-bottom-left-radius: 24px;
        border-bottom-right-radius: 24px;
    }
}

.contact-us {

    padding: 80px 0;

    .contact-title {

        font-size: 40px;
        font-weight: 500;
        margin-bottom: 25px;
    }

    .contact-form {

        border-radius: 15px;
        box-shadow: 0 4px 64px 0 rgba(0, 0, 0, 0.15);
        background-color: #fff;
        padding: 50px;

        .form-group {

            margin-bottom: 20px;

            label {

                margin-bottom: 15px;
                color: #262729;
                font-size: 15px;
            }

            .form-control {

                background-color: #e1ecf075;
                padding: 25px;
                border-color: #e1ecf0;
                border-radius: 10px;
                color: #262729;

                &:focus {

                    border-color: #262729;
                    box-shadow: none;
                }

                &::placeholder {

                    color: #262729;
                    opacity: .5;
                }
            }
        }

        .btn {

            background-color: #a60808;
            display: block;
            width: 100%;
            border-radius: 10px;
            padding: 20px;
            color: #fff;

            &:focus {

                box-shadow: none;
            }

            &.load {

                opacity: .5;
                cursor: not-allowed;
            }
        }
    }
}