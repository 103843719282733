.voice-over {
    padding-bottom: 80px;

    .voice-filter {
        background-color: #FBC723;
        padding: 30px 40px;
        margin-top: -50px;
        margin-bottom: 25px;
        position: relative;
        z-index: 11;

        .form-group {
            margin-bottom: 0;

            .form-control {
                border: none;
                border-radius: 0;
                border-left: 1px solid #FBC723;
                font-size: 12px;
                height: 55px;

                @media (max-width: 500px) {
                    border-bottom: 1px solid #FBC723;
                }

                &:focus {
                    box-shadow: none;
                }

                &::placeholder {
                    font-size: 12px;
                }
            }
        }
    }
    .voice-body {
        min-height: 100vh;
        position: relative;

        .loader {
            position: absolute;
            top: 10px;
            left: 0;
            bottom: 0;
            right: 0;
            background-color: rgba(255, 255, 255, 0.75);
            width: 100%;
            z-index: 2222;

            img {
                position: absolute;
                top: 20%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }
    .vos-box {

        box-shadow: 0 0 10px 5px #e6e6e6;
        padding: 20px;
        margin-top: 30px;

        .media {
            @media(max-width : 500px) {
                display: block;
            }
        }


        .voice-feats {

            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;

            @media(max-width : 500px) {
                padding: 0;
                justify-content: start;
            }
    
            li {
    
                margin: 20px 0;
                list-style: none;
                color: $theme-color;

                @media(max-width : 500px) {
                    width: 100%;
                    text-align: center;
                }
                

                span {

                    background-color: rgba(218, 218, 218, 0.35);
                    padding: 10px 15px;
                    border-radius: 20px;
                    font-weight: 500;

                    img {

                        width: 30px;
                        position: relative;
                        top: -2px;
                    }
                }
            }
        }
    }

    .vos-img {

        height: 100%;
    }

    .media-body {

        padding: 20px 10px;

        .audio-sam {

            audio {

                width: 100%;
            }
        }

    }

}