/*--------------------------
Call To Action Wrapper 
-----------------------------*/
.call-to-action {
    background: linear-gradient(145deg, #000000 0%, #000000d9 100%);
    position: relative;
    z-index: 2;
    &::before {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background-image: url('../../../../src/images/pattern-1.png');
        content: "";
        z-index: -1;
        opacity: 0.5;
    }
    .inner {
        > span {
            color: #fff;
            font-size: 15px;
            text-transform: uppercase;
            letter-spacing: 4px;
            display: block;
            margin-bottom: 9px;
        }
        h2 {
            color: #ffffff;
            font-size: 75px;
            font-weight: 900;
            font-family: 'Montserrat', sans-serif;
            line-height: 90px;
            @media #{$sm-layout} {
                font-size: 47px;
                line-height: 70px;
            }
        }
        
    }
    // &.bg_image {
    //     &::before {
    //         display: none;
    //     }
    // }

    a {
        // margin-top: 30px;
        background-color: $theme-color ;
        border-color: $theme-color  !important;
        border-radius: 0;
        font-size: 16px;
        color: #FFF;
            &:hover {
                background: transparent;
                border-color: #ffffff;
                color: $theme-color;
                
            }
    }
}