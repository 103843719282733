// Contact Styles 

.contact-form--1 {
    padding: 70px 0;
    label {
        display: block;
        margin-bottom: 0;
    }

    input,
    textarea {
        display: block;
        width: 100%;
        padding: 0 20px;
        border: 2px solid rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        transition: all 0.3s ease;
        height: 50px;
        line-height: 46px;
        margin-bottom: 20px;
        outline: none;
        color: #1f1f25;
        font-size: 15px;
        letter-spacing: 0.1px;
        &:focus {
            border-color: $theme-color;
        }
    }
    textarea {
        height: 120px;
    }

    .col-md-6 {

        // height: 300px;

        iframe {

            width: 100%;
        }


        .section-title {

            background-color: #FBC723;
            margin-top: 0;
            height: 450px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0 20px;

            .section-box {
                // text-align: center;

                h3 {

                    color: $theme-color;
                    font-size: 36px;
                }

                .icon-list {

                    margin: 50px 0;
                    padding: 0;

                    li {

                        list-style: none;
                        font-size: 16px;
                        font-weight: 500;
                        color: $theme-color;
                        line-height: 2.3;
                        span {

                            font-size: 18px;
                            color: #000;
                            padding-right: 10px;
                        }

                        a {
                            font-size: 16px;
                            font-weight: 500;
                            color: $theme-color;
                        }
                    }
                }

                .social-share {

                    li {

                        a {
                            color: #000;
                            border-color: #000;

                            &:hover {

                                background-color: rgb(255, 255, 255);
                            }
                        }
                    }
                }
            }
        }
    }

    
}


/*==========================
    Contact Address 
============================*/

.rn-address {
    margin-top: 30px;
    border: 1px solid #ebebeb;
    padding: 40px;
    border-radius: 10px;
    padding-top: 54px;
    background: #fff;
    padding-bottom: 50px;
    .icon {
        border: 1px solid #ebebeb;
        width: 78px;
        height: 78px;
        color: #FBC723;
        line-height: 73px;
        text-align: center;
        border-radius: 100%;
        background: #ffffff;
        font-size: 28px;
        position: absolute;
        top: -13px;
        left: 47px;
        @extend %transition;
    }
    .inner {
        h4 {
            &.title {
                font-size: 18px;
                font-weight: 700;
            }
        }
        p {
            font-size: 18px;
            margin-bottom: 0;
            color: rgba(29, 29, 36, 0.75);
            @media #{$lg-layout} {
                font-size: 16px;
            }
            @media #{$md-layout} {
                font-size: 16px;
            }
            @media #{$sm-layout} {
                font-size: 16px;
            }
            br {
                
                @media #{$lg-layout} {
                    display: none;
                }

                @media #{$md-layout} {
                    display: none;
                }

                @media #{$sm-layout} {
                    display: none;
                }
            }
            a {
                color: rgba(29, 29, 36, 0.75);
                @extend %transition;
                &:hover {
                    color: $theme-color;
                }
            }
        }
    }
    &:hover {
        .icon {
            background: $theme-color;
            border-color: $theme-color;
            color: #fff;
        }
    }
}

input,
textarea {
    @extend %transition;
}

.blog-comment-form .inner .rnform-group input:focus ,
.blog-comment-form .inner .rnform-group textarea:focus {
    border-color: $theme-color ;
}

.form-container {
    background-color: #FBC723;
    padding: 25px;
    min-height: 650px;

    .cus-input {

        border-color: rgb(0, 0, 0);

        &::placeholder {
            color: rgb(0, 0, 0);
        }
    }

    .alert {
        display: block;
    }
}

.map-ifram {

    height: 650px;

    iframe {

        height: 100%;
    }
}












