.wizzard {
    padding: 80px 0;

    .book-btn {

        display: block;
        background-color: $theme-color;
        color: #FFF;
        width: 100%;
        margin-top: 35px;
        padding: 20px;
        text-align: center;
        font-family: "Poppins",sans-serif;
        font-size: 18px;
    }

    .wizzard-body {

        background-color: #ffffff;
        padding: 40px;
        box-shadow: 0 5px 40px 5px rgba(112, 119, 125, 0.25);
        margin: 0 auto;

        .form-steps {
            display: none;
        }

        .step-title {
            border-bottom:1px solid #d5d5d5 ;
            margin: 0;
            padding-bottom: 25px;
        }

        @media(max-width: 500px) {
            width: 100%;
            padding: 20px;
        }

        .engineers-container {

            margin-top: 50px;

            .engineers-box {

                text-align: center;
                box-shadow: 0 5px 40px 5px rgba(112, 119, 125, .25);
                padding: 20px;
                border-radius: 10px;
                margin-bottom: 30px;
                background-color: #FFF;
                display: block;

                &:hover {

                    background-color: $theme-color;

                    .engineers-name {
                        
                        color: #FFF;
                    }
                }

                .engineers-img {

                    margin-bottom: 15px;

                    img {

                        width: 150px;
                        height: 150px;
                        border-radius: 50%;
                        object-fit: cover;
                        object-position: top;
                    }
                }
                .engineers-name {
                        
                    font-size: 17px;
                    margin: 0;
                    font-weight: 500;
                }
            }

        }

        label {
            font-size: 12px;
            margin-top: 25px;
        }

        select {

            font-size: 12px;
            font-family: "Poppins",sans-serif;
            border: none ;
            border-bottom: 1px solid #ced4da ;
            border-radius: 0;
            margin: 15px 0;

            .main-select {

                font-weight: 600;
            }
        }


        input {

            border: none ;
            border-bottom: 1px solid #ced4da ;
            font-family: "Poppins",sans-serif;
            border-radius: 0;
            margin: 15px 0;
            &::placeholder {

                font-size: 12px;
            }

            &:focus {

                box-shadow: none;
                border-color: $theme-color;

            }
        }

        h6 {
            margin-top: 25px;
        }
    }

    .wizzard-btns {

        display: flex;
        justify-content: space-between;
        margin: 0 auto;
        background-color: #FFF;
        padding: 30px 20px;
        @media(max-width: 500px) {
            width: 100%;
        }

        .btn {

            padding: 10px 50px;
            background-color: $theme-color;
            color: #FFF;
            font-family: "Poppins",sans-serif;
            @media(max-width: 500px) {
                padding: 7px 35px;
            }
        }

        .disable {

            background-color: #DDD;
            cursor: not-allowed;
            border: none;
            
            &:focus {

                outline: none  !important;
                border: none;
                box-shadow: none;
            }
        }

        
    }

    .service-needed {

        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 25px;

        .single-service {

            display: flex;
            align-items: center;
            justify-content: space-around;
            width: 25%;
            margin: 10px 10px;
            padding: 7px 0;
            box-shadow: 0 0 6px 2px #eee;
            
            &.active {
                color: #FFF;
                background-color: #a60808;
            }
            cursor: pointer;
            @media(max-width: 500px) {
                width: 100%;
            }

            input {
                height: auto;
                width: auto;
                margin-left: 10px;
                display: inline-block;
                position: absolute;
                right: 10px;
                top: 50%;
                transform: translateY(-50%);
                margin: 0;

                
            }

            label {
                margin-bottom: 0;
                cursor: pointer;
                width: 100%;
                position: relative;
                border: 1px solid rgba(44, 44, 44, 0.18);
                padding: 7px 10px;
                box-shadow: 0 0 6px 2px #eee;
                margin-top: 0;
            }
        }
    }

    .calendar {
        box-sizing: border-box;
        font-size: 1rem;
        @media(max-width : 500px) {
            font-size: .8rem;
        }

        .calender-header {

            display: flex;
            justify-content: space-between;
            padding: 10px;

            .prev, .next {

                cursor: pointer;
            }
        }

        .calender-body {

            background-color: #FFF;
            padding: 20px;
            min-height: 315px;
            @media(max-width : 500px) {

                min-height: auto;
            }

            .day-names {
                display: flex;
                justify-content: space-between;
                .week-day {
                    width: calc(100% / 7);
                    text-align: center;
                }
            }

            .week {
                display: flex;

                .day {
                    width: calc(100% / 7);
                    background-color: white;
                    padding: 10px;
                    cursor: pointer;
                    text-align: center;

                    @media(max-width : 500px) {

                        padding: 10px 5px;
                    }
        
                        &.selected {
        
                            background-color: $theme-color;
                            color: #FFF;
                            font-weight: 500;
                        }
        
                        &.before {
                            color:#DDD;
                        }
        
                        &.today  {
                            background-color: #DDD;
                        }
                }
            }

            
        }
        
    }

    .slots {



        .slots-header {

            padding: 10px;
            text-align: center;
            font-weight: 500;
            font-size: 18px;
        }

        .slots-body {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            background-color: #FFF;
            min-height: 315px;
            padding: 20px;

            .slot {
                width: calc(100% / 3);
                padding: 7px;
                .item {
                    text-align: center;
                    background-color: #EEE;
                    cursor: pointer;
                    font-size: 15px;
                    padding: 7px;

                    &.selected {
                        background-color: $theme-color;
                        color: #FFF;
                    }
                }
            }
        }
    }
}
.book-btn-slot {

    background-color: #DDD;
    text-align: center;
    cursor: pointer;
    padding: 7px 15px;

    &.book-act {
        color: #FFF;
        background-color: #a60808;
    }

}

.modal {

    // width: 50%;
    // margin: 0 auto;

    &.fade {

        .modal-dialog {
            max-width: 60%;

            @media (max-width : 500px) {
                max-width: 100%;
            }
        }
    }

    .modal-content {
        position: relative;
        .modal-header {
            justify-content: center;
            margin-top: 25px !important;

            .modal-title {
                font-size: 28px;
                color: $theme-color;
            }
            
            .close {
                position: absolute;
                top: 20px;
                right: 20px;
                background: none;
            }
        }
    }

    .modal-body {

        .solts-modal {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-evenly;
            margin: 0 auto;
            padding: 15px 0;

            .slot {

                width: 24%;
                padding: 10px;
                background-color: #FAFAFA;
                margin: 10px 0;
                text-align: center;
                cursor: pointer;

                @media (max-width : 500px) {
                    width: 30%;
                }

                &.active {
                    background-color: $theme-color;
                    color: #FFF;
                }
            }
        }

        .date-error {
            text-align: center;

            img {
                width: 50%;
            }
            h1 {
                font-size: 20px;
                margin-top: 25px;
                color: $theme-color;
            }
        }
    }
    .modal-btn {

        text-align: center;
        margin: 20px 0;
        border-top: 1px solid #b7b7b7;
        padding-top: 20px;

        button {
            background: none;
            border: none;
            font-size: 18px;
            background-color: $theme-color;
            color: #FFF;
            padding: 10px 30px;
        }
    }
}

.success-booking {

    .btn-finish {
        text-align: center;
        margin-top: 35px;
        a {
            background-color: #a60808;
            color: #fff;
            border-color: #a60808;
        }
    }
}
