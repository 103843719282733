.page-wrapper 
{
    &.account {

        padding: 50px  0;

        .profile {

            .profile-body {
                background-color: #FFF;
                box-shadow: 0 5px 40px 5px rgba(112, 119, 125, 0.25);
                padding: 40px;
                margin: 0 auto;
        
                .profile-header {
        
                    .title {
                        margin: 0;
                        margin-bottom: 20px;
                        font-size: 24px;
                    }
                    
                    .text {
        
                        font-size: 16px;
                        margin-bottom: 20px;
                    }
                }
        
                .profile-form {

                    label {
                        color: $theme-color;
                        font-weight: 500;
                        font-size: 12px;
                        // margin-top: 25px;
                    }
        
                    .form-control {
        
                        border: none ;
                        border-bottom: 1px solid #ced4da ;
                        font-family: "Poppins",sans-serif;
                        border-radius: 0;
                        // margin: 15px 0;
                        &::placeholder {
            
                            font-size: 12px;
                        }
            
                        &:focus {
            
                            box-shadow: none;
                            border-color: $theme-color;
            
                        }

                        &.email {
                            background-color: #DDD;
                            cursor: not-allowed;
                        }
                    }
        
                    .btn {
        
                        padding: 10px 50px;
                        background-color: $theme-color;
                        color: #FFF;
                        font-family: "Poppins",sans-serif;
                        @media(max-width: 500px) {
                            padding: 7px 35px;
                        }
                    }
                }
            }
        }
    }

    &.page-works {

        padding: 50px 0;

        .portfolio {

            padding-top: 0;
            margin: 15px 0;

            .content {
                position: static;
            }
        }

        .load-more {

            text-align: center;
            margin-top: 25px;

            button {
                background: none;
                border: none;
                padding: 10px 30px;
                font-size: 18px;
                background-color: $theme-color;
                color: #FFF;
            }
        }
    }
}