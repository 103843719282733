.auth {

    padding: 80px 0;
    background-color: #e4e4e4;

    .auth-body {
        background-color: #FFF;
        box-shadow: 0 5px 40px 5px rgba(112, 119, 125, 0.25);
        padding: 40px;
        width: 60%;
        margin: 0 auto;

        @media(max-width : 500px) {
            width: 100%;
            padding: 20px;
        }

        .auth-header {

            .title {
                margin: 0;
                margin-bottom: 20px;
                font-size: 24px;
            }
            
            .text {

                font-size: 16px;
                margin-bottom: 20px;
            }
        }

        .auth-form {

            .form-group {

                position: relative;

                .show-icon {
                    position: absolute;
                    top: 50%;
                    right: 20px;
                    transform: translateY(-50%);
                    cursor: pointer;
                }
            }

            input {

                border: none ;
                border-bottom: 1px solid #ced4da ;
                font-family: "Poppins",sans-serif;
                border-radius: 0;
                margin: 15px 0;
                &::placeholder {
    
                    font-size: 12px;
                }
    
                &:focus {
    
                    box-shadow: none;
                    border-color: $theme-color;
    
                }
            }

            .btn {

                padding: 10px 50px;
                background-color: $theme-color;
                color: #FFF;
                font-family: "Poppins",sans-serif;
                @media(max-width: 500px) {
                    padding: 7px 35px;
                }
            }

            .pincode-input-container {

                text-align: center;
                margin: 35px 0;

                .pincode-input-text {

                    background-color: #FFF !important;
                    border: solid 1px $theme-color !important;
                    border-radius: 5px;
                    margin: 0 10px !important;
                    font-weight: 600;

                    @media(max-width : 500px) {
                        width: 40px !important;
                        height: 40px !important;
                        margin: 0 6px !important;
                    }
                }
            }
        }

        .form-text {
            font-size: 16px;
            margin-top: 35px;
            font-weight: 600;
        }

        .feats {

            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 20px;

            @media(max-width : 500px) {
                display: block;
            }

            .auth-info {

                font-size: 14px;

                @media(max-width : 500px) {
                    margin-top: 25px;
                }

                a {
                    color: $theme-color;
                    font-weight: 600;
                }
            }
        }
    }
}